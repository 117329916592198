import React, { useState } from "react";
import dateImage from "assets/calendar today.png";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";

function DateModalMobile(props) {
  const { startDate, setStartDate, endDate, setEndDate } = props;

  // const [startDate, setStartDate] = useState(props?.startDate);

  const [dateValue, setDateValue] = useState(null);

  // const [endDate, setEndDate] = useState(props?.endDate);

  // const [selected, setSelected] = useState("12months")

  const [thisNull] = useState(null);

  const startDay = startDate?.toLocaleString("default", { day: "2-digit" });
  const startMonth = startDate?.toLocaleString("default", { month: "short" });
  const startYear = startDate?.toLocaleString("default", { year: "numeric" });
  let startDateFormat = `${startDay} ${startMonth}, ${startYear}`;

  const endDay = endDate?.toLocaleString("default", { day: "2-digit" });
  const endMonth = endDate?.toLocaleString("default", { month: "short" });
  const endYear = endDate?.toLocaleString("default", { year: "numeric" });
  let endDateFormat = `${endDay} ${endMonth}, ${endYear}`;

  const apiStartDay = startDate?.toLocaleString("default", { day: "2-digit" });
  const apiStartMonth = startDate?.toLocaleString("default", {
    month: "numeric",
  });
  const apiStartYear = startDate?.toLocaleString("default", {
    year: "2-digit",
  });
  let apiStartDateRangeFrom = `${apiStartYear}-${apiStartMonth}-${apiStartDay}`;

  const apiEndDay = endDate?.toLocaleString("default", { day: "2-digit" });
  const apiEndMonth = endDate?.toLocaleString("default", { month: "numeric" });
  const apiEndYear = endDate?.toLocaleString("default", { year: "2-digit" });
  let apiEndDateRangeTo = `${apiEndYear}-${apiEndMonth}-${apiEndDay}`;

  const onClickShow = () => {
    return {
      closeModal: props.closeModal(),
      onShow: props.onShow(
        props?.selectedDate === "custom"
          ? `${startDay}/${startMonth}/${startYear} - ${endDay}/${endMonth}/${endYear}`
          : dateValue
      ),
      dateRangeFrom: props.dateRangeFrom(
        props?.selectedDate === "custom" ? apiStartDateRangeFrom : thisNull
      ),
      dateRangeTo: props.dateRangeTo(
        props?.selectedDate === "custom" ? apiEndDateRangeTo : thisNull
      ),
    };
  };

  const filterReset = () => {
    setDateValue(null);
    props?.setSelectedDate(null);
    return {
      selected: props.selected(null),
      onShow: props.onShow(null),
      dateRangeFrom: props.dateRangeFrom(null),
      dateRangeTo: props.dateRangeTo(null),
      onStartDate: props.onStartDate(null),
      onEndDate: props.onEndDate(null),
      setStartDate: setStartDate(new Date()),
      setEndDate: setEndDate(new Date()),
    };
  };

  var dayOneYear = new Date();
  dayOneYear.setFullYear(dayOneYear.getFullYear() - 1);

  const onSelectOption = () => {
    return {
      dateRangeFrom: props.dateRangeFrom(null),
      dateRangeTo: props.dateRangeTo(null),
      onStartDate: props.onStartDate(null),
      onEndDate: props.onEndDate(null),
      setStartDate: setStartDate(new Date()),
      setEndDate: setEndDate(new Date()),
    };
    // return {
    //   setStartDate: setStartDate(new Date()),
    //   setEndDate: setEndDate(new Date()),
    //   dateRangeFrom: props.dateRangeFrom(null),
    //   dateRangeTo: props.dateRangeTo(null),
    //   onStartDate: props.onStartDate(null),
    //   onEndDate: props.onEndDate(null)
    // }
  };
  // console.log(props.selected)
  // console.log(startDate);
  return (
    <div className="px-5 overflow-y-scroll h-full bg-white">
      <form
        onChange={(e) => {
          props.selected(
            e.target.value === "year" ||
              e.target.value === "month" ||
              e.target.value === "week"
              ? e.target.value
              : null
          );
          setDateValue(e.target.id);
        }}
      >
        <div className="mb-1 flex font-semibold text-base pt-4 pb-4 items-center justify-between">
          <label>This Week</label>
          <input
            type="radio"
            id="This Week"
            value="week"
            className=""
            checked={props?.selectedDate === "week"}
            onChange={(e) => {
              props?.setSelectedDate(e.target.value);
              onSelectOption();
            }}
          />
        </div>
        <div className="border-b border-solid border-center-modal-border w-full" />

        <div className="bg-white mb-1 flex font-semibold text-base pt-4 pb-4 items-center justify-between">
          <label>This Month</label>
          <input
            type="radio"
            id="This Month"
            value="month"
            className=""
            checked={props?.selectedDate === "month"}
            onChange={(e) => {
              props?.setSelectedDate(e.target.value);
              onSelectOption();
            }}
          />
        </div>
        <div className="border-b border-solid border-center-modal-border w-full" />

        <div className="bg-white mb-1 flex font-semibold text-base pt-4 pb-4 items-center justify-between">
          <label>This Year</label>
          <input
            type="radio"
            id="This Year"
            value="year"
            className=""
            checked={props?.selectedDate === "year"}
            onChange={(e) => {
              props?.setSelectedDate(e.target.value);
              onSelectOption();
            }}
          />
        </div>
        <div className="border-b border-solid border-center-modal-border w-full" />

        <div className="bg-white mb-1 flex font-semibold text-base pt-4 pb-4 items-center justify-between">
          <label>Last 12 Months</label>
          <input
            type="radio"
            id="Last 12 Months"
            value="12months"
            className=""
            checked={props?.selectedDate === "12months"}
            onChange={(e) => {
              props?.setSelectedDate("12months");
              onSelectOption();
            }}
          />
        </div>
        <div className="border-b border-solid border-center-modal-border w-full" />

        <div className="bg-white mb-1 flex-1 font-semibold text-base pt-4 pb-4 items-center justify-between">
          <div
            checked={props?.selectedDate === "custom"}
            onChange={(e) => props?.setSelectedDate("custom")}
            onClick={() => props?.setSelectedDate("custom")}
          >
            <div className="flex justify-between items-center mb-2">
              <label>Custom</label>
              <input
                type="radio"
                value="custom"
                id="custom"
                className=""
                checked={props?.selectedDate === "custom"}
                onChange={(e) => props?.setSelectedDate("custom")}
              />
            </div>

            <div className="flex">
              <div className="font-custom text-sm font-normal">
                From
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    props.onStartDate(date);
                    setStartDate(date);
                  }}
                  customInput={
                    <div className="mt-3 flex flex-row justify-between relative border border-solid border-date-range rounded-lg py-3 px-1 font-semibold text-base font-custom w-11/12 xs:pr-12">
                      <input
                        type="text"
                        id="custom"
                        placeholder={`${startDateFormat}`}
                        className="w-11/12"
                        disabled
                      />
                      <img
                        src={dateImage}
                        alt=""
                        className="w-5 absolute right-2"
                      />
                    </div>
                  }
                  useWeekdaysShort={true}
                  dateFormat="dd MMM, yyyy"
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>

              <div className="font-custom text-sm font-normal">
                To
                <DatePicker
                  selected={endDate}
                  startDate={startDate}
                  onChange={(date) => {
                    setEndDate(date);
                    props.onEndDate(date);
                  }}
                  customInput={
                    <div className="mt-3 flex flex-row relative justify-between border border-solid border-date-range rounded-lg py-3 px-1 font-semibold text-base font-custom w-12/12 xs:pr-10">
                      <input
                        type="text"
                        id="custom"
                        placeholder={`${endDateFormat}`}
                        className="w-11/12"
                        disabled
                      />
                      <img
                        src={dateImage}
                        alt=""
                        className="w-5 absolute right-2"
                      />
                    </div>
                  }
                  useWeekdaysShort={true}
                  endDate={endDate}
                  dateFormat="dd MMM, yyyy"
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
            <div className="pt-5 border-b border-solid border-center-modal-border w-full" />
          </div>
        </div>
      </form>
      <div className="bg-white pt-2 pb-40">
        <div className="flex justify-between">
          <button
            className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-7 xxs:px-10.5 xs:px-14 lg:px-16 text-border-color"
            onClick={filterReset}
          >
            Reset Filter
          </button>

          <button
            className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-11.5 xxs:px-15 xs:px-17 lg:px-20 ml-2 bg-border-color text-white"
            onClick={onClickShow}
          >
            Show
          </button>
        </div>
      </div>
    </div>
  );
}

DateModalMobile.propTypes = {
  closeModal: PropTypes.func,
  onShow: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  dateRangeFrom: PropTypes.func,
  dateRangeTo: PropTypes.func,
  onStartDate: PropTypes.func,
  onEndDate: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
};

export default DateModalMobile;

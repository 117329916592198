import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
// import { Link } from 'react-router-dom';
import closeLogo from "assets/closeWhite.png";
import services from "pages/Login/services/index";

function MobileNav(props) {
  const { isOpen, toggleOpen } = props;

  const [detailResponse, setResponse] = useState({
    data: [],
  });

  const onLogout = () => {
    services.logout({
      success: (succ) => {
        console.log({ succ });
      },
      error: (err) => {
        console.log({ err });
      },
    });
  };

  useEffect(() => {
    services.getUserProfile({
      success: (response) => {
        setResponse((prev) => ({ ...prev, data: response.data }));
      },
      error: (err) => {
        console.log({ err });
      },
    });
  }, []);
  return (
    <SwipeableDrawer
      className="block bg-white"
      anchor="right"
      open={isOpen}
      onOpen={toggleOpen}
      onClose={toggleOpen}
    >
      <div
        className="px-4 py-5 w-screen h-screen lg:hidden relative"
        style={{
          background:
            "linear-gradient(180deg, rgba(82, 108, 139, 0.4) 0%, #2E496A 100%)",
        }}
      >
        <div className="flex justify-end">
          <button onClick={toggleOpen}>
            <img alt="" src={closeLogo} />
          </button>
        </div>
        <div className="flex justify-center items-center mt-24 pt-2 font-custom font-semibold text-sm text-white">
          {detailResponse?.data?.fullname}
        </div>
        <div className="flex justify-center items-center font-custom font-normal text-sm text-neutral-color">
          {detailResponse?.data?.email}
        </div>
        <button
          onClick={() => onLogout()}
          className="absolute bottom-10 left-1/2 -ml-32 py-4 px-28 flex justify-center items-center bg-white border border-solid box-border rounded-lg border-windows-login"
        >
          Log Out
        </button>
      </div>

      <div
        className="px-4 py-5 hidden lg:flex lg:flex-col h-screen lg:w-72 relative"
        style={{
          background: "linear-gradient(180deg, #2E496A 0%, #526C8B 100%)",
        }}
      >
        <div className="flex justify-end">
          <button onClick={toggleOpen}>
            <img alt="" src={closeLogo} />
          </button>
        </div>
        <div className="flex justify-center items-center mt-24 pt-2 font-custom font-semibold text-sm text-white">
          {detailResponse?.data?.fullname}
        </div>
        <div className="flex justify-center items-center font-custom font-normal text-sm text-neutral-color">
          {detailResponse?.data?.email}
        </div>
        <button
          onClick={() => onLogout()}
          className="absolute bottom-10 left-1/2 -ml-20 py-4 px-16 flex justify-center items-center bg-white border border-solid box-border rounded-lg border-windows-login"
        >
          Log Out
        </button>
      </div>
    </SwipeableDrawer>
  );
}

MobileNav.propTypes = {
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
};

MobileNav.defaultProps = {
  isOpen: false,
};

export default MobileNav;

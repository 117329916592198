import token from "utils/session";
import endpoint from "utils/endpoint";
import axios from "axios";

async function getUser(params, callback) {
  const url = `${endpoint.user}`;
  const config = {
    method: "get",
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function getUserSearch(params, callback) {
  const url = `${endpoint.user}?user_type=admin&is_assigned=0`;
  const config = {
    method: "get",
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function updateUserAdmin(params, callback) {
  const url = `${endpoint.user}/assign-admin`;
  const config = {
    method: "post",
    data: {
      user_id: params.user_id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function getRole(callback) {
  const url = endpoint.role;
  const config = {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    await callback.success(response.data);
  } catch (error) {
    console.log(error);
    // await callback.error(error);
  }
}

async function getPermissions(callback) {
  const url = endpoint.permission;
  const config = {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    await callback.success(response.data);
  } catch (error) {
    // console.log(error);
    await callback.error(error);
  }
}

async function updateUser(params) {
  const url = `${endpoint.user}/${params.id}`;
  const config = {
    method: "PATCH",
    data: {
      fullname: params.fullname,
      email: params.email,
      role_id: params.role_id,
      status: params.status,
      is_assigned: params.is_assigned,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    await console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

async function updateRole(params) {
  const url = `${endpoint.role}/${params.id}`;
  const config = {
    method: "PATCH",
    data: {
      name: params.name,
      permissions: params.permissions,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    await console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

async function newRole(params) {
  const url = `${endpoint.role}`;
  const config = {
    method: "POST",
    data: {
      name: params.name,
      permissions: params.permissions,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    await console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

async function deleteUser(params) {
  const url = `${endpoint.user}/${params.id}`;
  const config = {
    method: "DELETE",
    data: {
      fullname: params.fullname,
      email: params.email,
      role_id: params.role_id,
      status: params.status,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    await console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

async function deleteRole(params) {
  const url = `${endpoint.role}/${params.id}`;
  const config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    await console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

const admin = {
  getUser,
  getUserSearch,
  updateUserAdmin,
  getRole,
  getPermissions,
  updateUser,
  updateRole,
  newRole,
  deleteUser,
  deleteRole,
};

export default admin;

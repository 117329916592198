import Cookie from "js-cookie";
import token from "utils/session";
import endpoint from "utils/endpoint";
import axios from "axios";

async function loginAzure(callback) {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const state = queryParams.get("state");

  if (!code && !state) {
    window.location.replace("/login/azure");
  }

  const url = endpoint.token;
  const config = {
    method: "POST",
    data: {
      code,
      state,
    },
  };

  try {
    const response = await axios(url, config);
    const expires = 80 * 80 * 1000;
    const inTwoHour = new Date(new Date().getTime() + expires);
    var json_str = JSON.stringify(response.data.permissions);
    Cookie.set("SID_OR", response.data.token, inTwoHour);
    Cookie.set("SID_OR_PERMISSIONS", json_str, inTwoHour);
    Cookie.set("SID_OR_USER_TYPE", response.data.user_type, inTwoHour);
    if (response.data.user_type === "user") {
      window.location.replace("/");
    } else if (response.data.user_type === "admin") {
      window.location.replace("/admin");
    }
  } catch (error) {
    await callback.error(error);
  }
}

async function logout(callback) {
  const url = endpoint.logout;

  const config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    await axios(url, config);
    Cookie.remove("SID_OR");
    Cookie.remove("SID_OR_PERMISSIONS");
    Cookie.remove("SID_OR_USER_TYPE");
    window.location.replace("/login");
  } catch (error) {
    await callback.error(error);
    console.log(error);
  }
}

async function getUserProfile(callback) {
  const url = endpoint.profile;

  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(url, config);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
    console.log(error);
  }
}

const login = {
  loginAzure,
  logout,
  getUserProfile,
};
export default login;

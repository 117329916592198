import React, { useState, useEffect } from "react";
import FilterButton from "components/filterButton";
import { Modal, CenterModal } from "components/modal";
import FilterModal from "components/modal/FilterActivityModal";
import FilterDealershipModal from "components/modal/FilterDealershipModal";
import DateModal from "components/modal/DateModal";
import DateModalMobile from "components/modal/DateModalMobile";
import SortModal from "components/modal/SortModal";
import expandMore from "assets/expand more.png";
import moreExpand from "assets/down-s-line.png";
import dateImage from "assets/calendar today.png";
import imageDate from "assets/calendar.png";
import services from "../services";
import homeServices from "pages/Home/services";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { DateTime } from "luxon";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import { format } from "date-fns";
import Cookie from "js-cookie";
import { AdminContext } from "utils/context";
function Activity(props) {
  const {
    sortValue,
    filterBadge,
    filterDealershipBadge,
    filterType,
    filterDealership,
    sortSelected,
    activitySelected,
    dealershipSelected,
    dealershipFilter,
    optionsFilter,
    dateRangeFrom,
    dateRangeTo,
    startDate,
    endDate,
    dateCustom,
    dateRange,
    selected,

    setSortValue,
    setFilterBadge,
    setFilterDealershipBadge,
    setFilterType,
    setFilterDealership,
    setSortSelected,
    setActivitySelected,
    setDealershipSelected,
    setDealershipFilter,
    setOptionsFilter,
    setDateRangeFrom,
    setDateRangeTo,
    setStartDate,
    setEndDate,
    setDateCustom,
    setDateRange,
    setSelected,
  } = React.useContext(AdminContext);

  const [isLoading, setIsLoading] = useState(true);

  const [modalState, setModalState] = useState({
    sortModal: false,
    filterModal: false,
    dateModal: false,
    dealershipModal: false,
  });

  const { id } = useParams();

  const [detailResponse, setResponse] = useState({});

  const [listFilterDealership, setListFilterDealership] = useState({
    data: [],
    total: 0,
  });

  let dayOneYear = new Date();
  dayOneYear.setFullYear(dayOneYear.getFullYear() - 1);
  // let testDateNow = format(new Date(), "yyyy-MM-dd")

  useEffect(() => {
    services.getTransactionActivity(
      {
        id,
        sort: sortValue,
        daterange: dateRange,
        daterange_from:
          selected === "12months"
            ? format(dayOneYear, "yyyy-MM-dd")
            : selected !== "custom"
            ? null
            : format(startDate, "yyyy-MM-dd"),
        daterange_to:
          selected === "12months"
            ? format(endDate, "yyyy-MM-dd")
            : selected !== "custom"
            ? null
            : format(endDate, "yyyy-MM-dd"),
        dealership_code: filterDealership.includes("All")
          ? null
          : filterDealership.map((i) => i).join(","),
        type: filterType.includes("All")
          ? null
          : filterType.map((i) => i?.replace?.("Parts", "Part")).join(","),
      },
      (response, error) => {
        if (error) {
          console.log(error);
        } else {
          setIsLoading(false);
        }
        // console.log(response);
        return setResponse(response);
      }
    );

    homeServices.getDealershipOrag(
      { id },
      {
        success: (response) => {
          setListFilterDealership(response);
        },
        error: (err) => {
          console.log(err);
          if (err.message === "Request failed with status code 401") {
            Cookie.remove("SID_OR");
            Cookie.remove("SID_OR_PERMISSIONS");
            Cookie.remove("SID_OR_USER_TYPE");
            window.location.replace("/login");
          }
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortValue,
    dateRange,
    startDate,
    endDate,
    filterType,
    filterDealership,
    id,
  ]);

  const filterHandler = () => {
    setModalState({
      filterModal: !modalState.filterModal,
    });
  };

  const dealershipHandler = () => {
    setModalState({
      dealershipModal: !modalState.dealershipModal,
    });
  };

  const dateHandler = () => {
    setModalState({
      dateModal: !modalState.dateModal,
    });
  };

  const sortHandler = () => {
    setModalState({
      sortModal: !modalState.sortModal,
    });
  };
  // console.log({dateRange,dateRangeFrom,dateRangeTo});
  // console.log({startDate});
  const mobileFilter = classNames(
    "whitespace-nowrap mx-auto justify-between items-center flex lg:hidden space-x-5 sm:space-x-24 w-max",
    {
      "w-full overflow-x-scroll xxs:px-2 xs:px-5 px-4 sm:px-0":
        !dateRangeFrom && !dateRangeTo,
      "flex-1 overflow-x-scroll w-full px-1 xxs:px-6 xs:px-10":
        dateRangeFrom && dateRangeTo,
    }
  );

  const buttonClass = classNames(
    "py-1 px-2 lg:pl-2 lg:pr-2 border border-solid border-border-color rounded-3xl"
  );

  const buttonClassMobile = classNames(
    "py-1 border border-solid border-border-color rounded-3xl text-xs items-center px-1"
  );
  // setState(prev => ({...prev, filterDealershipBadge: filterDealershipBadge+1 }))
  return (
    <div className="font-custom">
      <div className="mt-4 flex justify-between lg:w-full mx-auto">
        <div
          className={`flex-1 lg:flex-none justify-around items-center hidden lg:flex lg:w-3/4 xl:w-1/2 lg:pl-10 xl:pl-26 ${
            dateCustom?.length > 12 ? "lg:pl-4 xl:pl-0" : ""
          }`}
        >
          <FilterButton
            className={classNames(
              buttonClass,
              filterBadge !== 0
                ? "bg-border-color text-white"
                : "text-border-color"
            )}
            placeholderName="Filter by:"
            options={
              optionsFilter?.indexOf("All") === 0
                ? "All Activity"
                : optionsFilter.length === 1
                ? optionsFilter
                : optionsFilter.length === 0
                ? "All Activity"
                : "Multiple Values"
            }
            onClick={(filter) => filterHandler(filter)}
            imageIcon={filterBadge !== 0 ? moreExpand : expandMore}
            // filterBadge={filterBadge}
            onImageOpen={modalState?.filterModal}
          />

          <FilterButton
            className={classNames(
              buttonClass,
              filterDealershipBadge !== 0
                ? "bg-border-color text-white"
                : "text-border-color"
            )}
            placeholderName="Filter by:"
            options={
              dealershipFilter?.indexOf("All") === 0
                ? "All Dealership"
                : dealershipFilter.length === 1
                ? dealershipFilter
                : dealershipFilter.length === 0
                ? "All Dealership"
                : "Multiple Values"
            }
            onClick={(filter) => dealershipHandler(filter)}
            imageIcon={filterDealershipBadge !== 0 ? moreExpand : expandMore}
            onImageOpen={modalState?.dealershipModal}
          />

          <FilterButton
            className={classNames(
              buttonClass,
              dateCustom !== null
                ? "bg-border-color text-white"
                : "text-border-color"
            )}
            placeholderName="Date Range:"
            options={"Last 12 Months"}
            onClick={(date) => {
              dateHandler(date);
            }}
            imageIcon={dateCustom !== null ? imageDate : dateImage}
            dateRange={true}
            dateCustom={dateCustom}
            onImageOpen={modalState?.dateModal}
          />

          <FilterButton
            className={classNames(
              buttonClass,
              sortValue === "oldest"
                ? "bg-border-color text-white"
                : "bg-border-color text-white"
            )}
            placeholderName="Sort by:"
            options={sortValue === "oldest" ? "Oldest" : "Latest"}
            onClick={(sort) => sortHandler(sort)}
            imageIcon={sortValue === "oldest" ? moreExpand : moreExpand}
            onImageOpen={modalState?.sortModal}
          />
        </div>

        <div className={mobileFilter}>
          <FilterButton
            className={classNames(
              buttonClassMobile,
              filterBadge !== 0
                ? "bg-border-color text-white"
                : "text-border-color"
            )}
            placeholderName="Filter by:"
            options={
              optionsFilter?.indexOf("All") === 0
                ? "All Activity"
                : optionsFilter.length === 1
                ? optionsFilter
                : optionsFilter.length === 0
                ? "All Activity"
                : "Multiple Values"
            }
            onClick={(filter) => filterHandler(filter)}
            imageIcon={filterBadge !== 0 ? moreExpand : expandMore}
            // filterBadge={filterBadge}
            onImageOpen={modalState?.filterModal}
          />

          <FilterButton
            className={classNames(
              buttonClass,
              filterDealershipBadge !== 0
                ? "bg-border-color text-white"
                : "text-border-color"
            )}
            placeholderName="Filter by:"
            options={
              dealershipFilter?.indexOf("All") === 0
                ? "All Dealership"
                : dealershipFilter.length === 1
                ? dealershipFilter
                : dealershipFilter.length === 0
                ? "All Dealership"
                : "Multiple Values"
            }
            onClick={(filter) => dealershipHandler(filter)}
            imageIcon={filterDealershipBadge !== 0 ? moreExpand : expandMore}
            onImageOpen={modalState?.dealershipModal}
          />

          <FilterButton
            className={classNames(
              buttonClassMobile,
              dateCustom !== null
                ? "bg-border-color text-white"
                : "text-border-color"
            )}
            placeholderName="Date Range:"
            options={"Last 12 Months"}
            onClick={(date) => {
              dateHandler(date);
            }}
            imageIcon={dateCustom !== null ? imageDate : dateImage}
            dateRange={true}
            dateCustom={dateCustom}
            onImageOpen={modalState?.dateModal}
          />

          <FilterButton
            className={classNames(
              buttonClassMobile,
              sortValue === "oldest"
                ? "bg-border-color text-white"
                : "bg-border-color text-white"
            )}
            placeholderName="Sort by:"
            options={sortValue === "oldest" ? "Oldest" : "Latest"}
            onClick={(sort) => sortHandler(sort)}
            imageIcon={sortValue === "oldest" ? moreExpand : moreExpand}
            onImageOpen={modalState?.sortModal}
          />
        </div>

        <Modal
          onFilterModal={true}
          title={"Filter"}
          show={modalState?.filterModal}
          closeModal={() => filterHandler()}
        >
          <FilterModal
            optionsFilter={optionsFilter}
            setOptionsFilter={setOptionsFilter}
            activitySelected={activitySelected}
            setActivitySelected={setActivitySelected}
            dealershipSelected={dealershipSelected}
            setDealershipSelected={setDealershipSelected}
            listDealership={listFilterDealership?.data}
            filterType={filterType}
            setFilterType={setFilterType}
            filterDealership={filterDealership}
            setFilterDealership={setFilterDealership}
            closeModal={() => filterHandler()}
            onFilterBadge={setFilterBadge}
            filterBadge={filterBadge}
          />
        </Modal>

        <CenterModal
          title={"Filter"}
          show={modalState?.filterModal}
          closeModal={() => filterHandler()}
        >
          <FilterModal
            optionsFilter={optionsFilter}
            setOptionsFilter={setOptionsFilter}
            activitySelected={activitySelected}
            setActivitySelected={setActivitySelected}
            dealershipSelected={dealershipSelected}
            setDealershipSelected={setDealershipSelected}
            listDealership={listFilterDealership?.data}
            filterType={filterType}
            setFilterType={setFilterType}
            filterDealership={filterDealership}
            setFilterDealership={setFilterDealership}
            closeModal={() => filterHandler()}
            onFilterBadge={setFilterBadge}
            filterBadge={filterBadge}
          />
        </CenterModal>

        <Modal
          onFilterModal={true}
          title={"Filter"}
          show={modalState?.dealershipModal}
          closeModal={() => dealershipHandler()}
        >
          <FilterDealershipModal
            optionsFilter={dealershipFilter}
            setOptionsFilter={setDealershipFilter}
            activitySelected={activitySelected}
            setActivitySelected={setActivitySelected}
            dealershipSelected={dealershipSelected}
            setDealershipSelected={setDealershipSelected}
            listDealership={listFilterDealership?.data}
            filterType={filterType}
            setFilterType={setFilterType}
            filterDealership={filterDealership}
            setFilterDealership={setFilterDealership}
            closeModal={() => dealershipHandler()}
            onFilterBadge={setFilterDealershipBadge}
            filterBadge={filterDealershipBadge}
          />
        </Modal>

        <CenterModal
          title={"Filter"}
          show={modalState?.dealershipModal}
          closeModal={() => dealershipHandler()}
        >
          <FilterDealershipModal
            optionsFilter={dealershipFilter}
            setOptionsFilter={setDealershipFilter}
            activitySelected={activitySelected}
            setActivitySelected={setActivitySelected}
            dealershipSelected={dealershipSelected}
            setDealershipSelected={setDealershipSelected}
            listDealership={listFilterDealership?.data}
            filterType={filterType}
            setFilterType={setFilterType}
            filterDealership={filterDealership}
            setFilterDealership={setFilterDealership}
            closeModal={() => dealershipHandler()}
            onFilterBadge={setFilterDealershipBadge}
            filterBadge={filterDealershipBadge}
          />
        </CenterModal>

        <Modal
          title={"Date Range"}
          onDateModal={true}
          show={modalState?.dateModal}
          closeModal={() => dateHandler()}
        >
          <DateModalMobile
            selectedDate={selected}
            setSelectedDate={setSelected}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selected={setDateRange}
            onShow={(date) => setDateCustom(date)}
            closeModal={() => dateHandler()}
            onStartDate={(date) => setStartDate(date)}
            onEndDate={(date) => setEndDate(date)}
            dateRangeFrom={setDateRangeFrom}
            dateRangeTo={setDateRangeTo}
          />
        </Modal>

        <CenterModal
          title={"Date Range"}
          onDateModal={true}
          show={modalState?.dateModal}
          closeModal={() => dateHandler()}
        >
          <DateModal
            selectedDate={selected}
            setSelectedDate={setSelected}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selected={setDateRange}
            onShow={(date) => setDateCustom(date)}
            closeModal={() => dateHandler()}
            onStartDate={(date) => setStartDate(date)}
            onEndDate={(date) => setEndDate(date)}
            dateRangeFrom={setDateRangeFrom}
            dateRangeTo={setDateRangeTo}
          />
        </CenterModal>

        <Modal
          onSortModal={true}
          title={"Sort by"}
          show={modalState?.sortModal}
          closeModal={() => sortHandler()}
        >
          <SortModal
            sortSelected={sortSelected}
            setSortSelected={setSortSelected}
            closeModal={() => sortHandler()}
            setSortValue={setSortValue}
          />
        </Modal>

        <CenterModal
          onSortModal={true}
          title={"Sort by"}
          show={modalState?.sortModal}
          closeModal={() => sortHandler()}
        >
          <SortModal
            sortSelected={sortSelected}
            setSortSelected={setSortSelected}
            closeModal={() => sortHandler()}
            setSortValue={setSortValue}
          />
        </CenterModal>
      </div>
      <div className="mt-6 pb-20">
        {isLoading && (
          <div className="flex w-full justify-center">
            <CircularProgress color="black" size={20} />
          </div>
        )}
        {detailResponse?.data?.map((data, index) => {
          // const dateStart = data.engage_date;
          // array.sort((a,b) => {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          //   return new Date(b.date) - new Date(a.date);
          // });
          // const date = dateStart.toLocaleString('default', { day: '2-digit' });
          return (
            <div className="mt-6 mb-4" key={index}>
              <div className="w-5/6 mx-auto font-custom font-semibold text-base pb-2">
                {data.name}
              </div>
              {data?.data?.map((data, index) => {
                const dt = DateTime.fromISO(data.engage_date);
                const monthNames = [
                  "",
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ];
                const dtMonth = monthNames?.[dt?.c?.month];
                return (
                  <div className="w-5/6 mx-auto bg-white px-4 mb-2" key={index}>
                    <div className="flex flex-row justify-between py-4">
                      <div className="font-semibold	text-base">
                        {data.trx_type === "Part" ? "Parts" : data.trx_type}
                      </div>
                      <div className="font-custom font-normal text-sm text-neutral-color">
                        {/* 01 July 2021 */}
                        {dt?.c?.day} {dtMonth} {dt?.c?.year}
                      </div>
                    </div>
                    <div className="pt-1 pb-4 font-custom text-sm font-normal">
                      <div>Dealership : {data.dealership_name}</div>
                      {data.trx_type === "Service" && (
                        <div>Vehicle : {data.trx_name}</div>
                      )}
                      {data.trx_type === "Vehicle Purchase" && (
                        <div>Vehicle : {data.trx_name}</div>
                      )}
                      <div>Total Spending : $ {data.engage_spent}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

Activity.propTypes = {
  classContainer: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func,
  dateCustom: PropTypes.string,
  setDateCustom: PropTypes.func,
  dateRange: PropTypes.string,
  setDateRange: PropTypes.func,
  dateRangeFrom: PropTypes.string,
  setDateRangeFrom: PropTypes.func,
  dateRangeTo: PropTypes.string,
  setDateRangeTo: PropTypes.func,
};

Activity.defaultProps = {};

export default Activity;

import React, { useState, useEffect } from "react";
import services from "pages/Admin/services";
import { CenterModalAdmin } from "components/modal";
// import AddNewRole from 'components/modal/AddNewRole';

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import Cookie from "js-cookie";

import DeleteLogo from "assets/delete.png";
import DeleteAlert from "assets/warning.png";

function RolesPermission(props) {
  const [detailResponse, setResponse] = useState({
    permissions: [],
  });

  useEffect(() => {
    setParams(
      roles.permissions.data?.permissions?.map((item, prev) => ({
        ...prev,
        name: item,
        position: true,
      }))
    );

    services.getPermissions({
      success: (response) => {
        setResponse((prev) => ({ ...prev, permissions: response }));
      },
      error: (err) => {
        console.log(err);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedPermissionsModal, setSelectedPermissionModal] = useState(null);
  const [names, setNames] = useState(null);

  let nameAddModal = names;
  let permissionsAddModal = selectedPermissionsModal;
  const onSaveChangesAddModal = () => {
    return {
      addRoleHandler: addRoleHandler(),
      // setParams(prev => ({...prev, onUpdated: !params.onUpdated})),
      newRole: services.newRole(
        {
          name: nameAddModal,
          permissions: permissionsAddModal,
        },
        (response, err) => {
          if (!err) {
            // setSelected(true);
            console.log(response);
            setSelectedPermissionModal(null);
            setNames(null);
          }
        }
      ),
    };
  };

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const handleOnChangeAddModal = (event, permission) => {
    if (event.target.checked) {
      setSelectedPermissionModal((prev) =>
        prev !== null ? [...prev, event.target.name] : [event.target.name]
      );
    } else {
      setSelectedPermissionModal((prev) => {
        return prev.filter((i) => i !== event.target.name);
      });
    }
  };

  const [roles, setRoles] = useState({
    data: [],
    permissions: [],
    onSelectedUser: {},
    selectedPermissions: null,
  });

  const [params, setParams] = useState({
    onUpdated: false,
    onSelectUser: {},
  });

  // const [stateRerender, setStateRerender] = useState(false)

  const [modalState, setModalState] = useState({
    editRoleModal: false,
    addRoleModal: false,
    confirmDeleteRole: false,
    testModal: false,
  });

  const editRoleHandler = (data) => {
    setRoles((prev) => ({ ...prev, onSelectedUser: data }));
    setModalState((prev) => ({
      ...prev,
      editRoleModal: !modalState.editRoleModal,
    }));
    services.getRole({
      success: (response) => {
        setRoles((prev) => ({ ...prev, data: response }));
      },
      error: (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      },
    });

    services.getPermissions({
      success: (response) => {
        setRoles((prev) => ({ ...prev, permissions: response }));
      },
      error: (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      },
    });
    // setParams(prev => ({ ...prev, onUpdated: !params.onUpdated}))
  };

  const addRoleHandler = (data) => {
    // setRoles(prev => ({ ...prev, onSelectedUser: data }));
    setModalState((prev) => ({
      ...prev,
      addRoleModal: !modalState.addRoleModal,
    }));
    services.getRole({
      success: (response) => {
        setRoles((prev) => ({ ...prev, data: response }));
      },
      error: (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      },
    });

    services.getPermissions({
      success: (response) => {
        setRoles((prev) => ({ ...prev, permissions: response }));
      },
      error: (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      },
    });
    // setParams(prev => ({ ...prev, onUpdated: !params.onUpdated}))
  };

  const deleteRoleHandler = () => {
    setModalState((prev) => ({
      ...prev,
      confirmDeleteRole: !modalState.confirmDeleteRole,
    }));
    services.getRole({
      success: (response) => {
        setRoles((prev) => ({ ...prev, data: response }));
      },
      error: (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      },
    });

    services.getPermissions({
      success: (response) => {
        setRoles((prev) => ({ ...prev, permissions: response }));
      },
      error: (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      },
    });
    // setParams(prev => ({ ...prev, onUpdated: !params.onUpdated}))
  };

  // console.log(roles?.onSelectedUser?.id);
  const onDeleteRole = () => {
    return (
      // setParams(prev => ({ ...prev, onUpdated: !params.onUpdated, onSelectUser: null})),
      services.deleteRole(
        {
          id: roles?.onSelectedUser?.id,
        },
        (response, err) => {
          if (!err) {
            console.log(response);
          } else {
            console.log(err);
          }
        }
      )
    );
  };

  const [selectedPermissions, setSelectedPermission] = React.useState(null);

  const handleOnChange = (event, permission) => {
    if (event.target.checked) {
      setSelectedPermission((prev) => [...prev, event.target.name]);
    } else {
      setSelectedPermission((prev) => {
        return prev.filter((i) => i !== event.target.name);
      });
    }
  };

  const id = roles?.onSelectedUser?.id;
  const name = roles?.onSelectedUser?.name;
  let permissions = selectedPermissions;
  const onSaveChanges = () => {
    return (
      // setParams(prev => ({ ...prev, onUpdated: !params.onUpdated, onSelectUser: null})),
      services.updateRole(
        {
          id,
          name,
          permissions,
        },
        (response, err) => {
          if (!err) {
            // setSelected(true);
            console.log(response);
            setModalState((prev) => ({
              ...prev,
              testModal: !modalState.testModal,
            }));
          }
        }
      )
    );
  };

  useEffect(() => {
    services.getRole({
      success: (response) => {
        setRoles((prev) => ({ ...prev, data: response }));
      },
      error: (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      },
    });

    services.getPermissions({
      success: (response) => {
        setRoles((prev) => ({ ...prev, permissions: response }));
      },
      error: (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      },
    });
  }, [params, roles.selectedPermissions, modalState]);

  useEffect(() => {
    setParams((prev) => ({ ...prev, onSelectUser: roles.onSelectedUser }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function titleCase(str) {
  //   var splitStr = str.toLowerCase().split(' ');
  //   for (var i = 0; i < splitStr.length; i++) {
  //       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  //   }
  //   return splitStr.join(' ');
  // }
  // console.log(roles.permissions.data)
  return (
    <div className="">
      <div className="flex flex-col lg:flex-row mx-auto ml-3 lg:ml-0 justify-between xl:mx-3">
        <div className="font-custom font-semibold text-2xl text-black-color pb-6 lg:ml-10 pt-6 ml-2">
          Permission
        </div>
        <div className="mr-10">
          <button
            onClick={() => {
              addRoleHandler();
              setParams((prev) => ({ ...prev, onUpdated: !params.onUpdated }));
            }}
            className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-2 px-4 my-6 m-2 bg-border-color text-white lg:mx-auto"
          >
            Add New Roles & Permission
          </button>
        </div>
      </div>
      <div className="overflow-x-auto w-11/12 mx-auto">
        <table className="bg-white w-full mx-auto">
          <thead>
            <tr
              className="font-custom font-semibold text-base border-b border-solid border-center-modal-border"
              style={{ color: "#272833" }}
              height="50"
            >
              <th className="text-left px-10 lg:px-8">Role</th>
              <th className="text-left px-10 lg:px-8">Permission</th>
              <th className="text-left">Assigned User</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {roles?.data?.data
              ?.filter((data) => data.name !== "Admin")
              ?.map((data) => {
                return (
                  <tr
                    className="border-b border-solid border-center-modal-border"
                    key={data.id}
                  >
                    <td className="text-left px-10 lg:px-8">{data.name}</td>
                    <td className="text-left px-10 lg:px-8">
                      {data.permissions.length}
                    </td>
                    <td className="text-left">{data?.user_count}</td>
                    <td></td>
                    <td width="20">
                      <button
                        onClick={() => {
                          editRoleHandler(data);
                          modalState.editRoleModal === true
                            ? setSelectedPermission((prev) => [...prev])
                            : setSelectedPermission(data?.permissions);
                          setParams((prev) => ({
                            ...prev,
                            onUpdated: !params.onUpdated,
                          }));
                        }}
                        className="my-5 mx-5 text-center border border-solid border-border-color text-sm font-custom font-semibold rounded py-2 px-3"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <CenterModalAdmin
        adminModal={true}
        title={"Edit Role"}
        show={modalState?.editRoleModal}
        closeModal={() => editRoleHandler()}
      >
        {modalState?.confirmDeleteRole === false && (
          <div className="h-full">
            <div className="bg-white xl:mb-1">
              <div className="flex flex-1 items-center px-4 pb-6 border-b border-solid border-filter-border">
                <div className="xl:py-6 w-full">
                  <div className="font-custom font-semibold text-base text-black-color opacity-80">
                    Role Name
                  </div>
                  <div className="mt-2 border border-solid box-border border-edit-role flex flex-1 font-custom font-semibold text-base text-black-color py-3 pl-4 rounded-lg xl:pr-80 pr-32 opacity-80">
                    {roles?.onSelectedUser?.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-1 flex-col items-center px-4 xl:pb-6 xl:pt-6 border-b border-solid border-filter-border">
              <div className="font-custom self-start font-semibold text-base text-black-color opacity-80 xl:pb-4">
                Permission
              </div>
              {roles?.permissions?.data?.map((data, index) => {
                const isChecked = selectedPermissions?.indexOf(data) >= 0;
                return (
                  <div key={index} className="ml-4 w-full flex items-center">
                    <Box
                      sx={{
                        "& .MuiIconButton-label": { color: "#2E496A" },
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            color="#2E496A"
                            control={
                              <Checkbox
                                checked={isChecked}
                                name={data}
                                color="#2E496A"
                                onChange={handleOnChange}
                                className="font-custom font-normal text-sm text-black-color"
                              />
                            }
                            label={titleCase(data)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Box>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-between bg-white xl:pt-6 pt-3">
              <div></div>
              <div className="flex">
                <button
                  className="flex font-custom font-semibold text-base box-border border-2 border-solid border-error rounded-lg py-3 px-2 md:px-4 items-center bg-white text-error mx-auto mr-4"
                  onClick={() => {
                    deleteRoleHandler();
                  }}
                >
                  <img className="mr-2" src={DeleteLogo} alt="" />
                  Delete from Role
                </button>
                <button
                  className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-8 bg-border-color text-white mx-auto"
                  onClick={() => {
                    onSaveChanges();
                    editRoleHandler();
                    setParams((prev) => ({
                      ...prev,
                      onUpdated: !params.onUpdated,
                    }));
                    setRoles((prev) => ({
                      ...prev,
                      selectedPermissions: !roles.selectedPermissions,
                    }));
                  }}
                >
                  Save Change
                </button>
              </div>
            </div>
          </div>
        )}
      </CenterModalAdmin>
      <CenterModalAdmin
        deleteRoleModal={true}
        adminModal={true}
        title={"Edit Role"}
        show={modalState?.confirmDeleteRole}
        closeModal={() => {
          deleteRoleHandler();
          editRoleHandler();
        }}
      >
        {modalState?.confirmDeleteRole === true && (
          <div className="h-full">
            <div className="flex pt-8 flex-col justify-center items-center">
              <img src={DeleteAlert} alt="" />
              <div className="font-custom font-semibold text-black-color text-base">
                Are you sure to delete Role?
              </div>
            </div>

            <div className="flex justify-between bg-white pt-6">
              <div></div>
              <div className="flex">
                <button
                  className="flex font-custom font-semibold text-base py-3 md:px-10 px-5 items-center bg-white text-border-color mx-auto mr-4"
                  onClick={() => {
                    deleteRoleHandler();
                    editRoleHandler();
                  }}
                >
                  Cancel
                </button>

                <button
                  className="font-custom font-semibold text-base box-border border-2 border-solid border-error rounded-lg py-3 px-12 md:px-16 bg-error text-white mx-auto"
                  onClick={() => {
                    setParams((prev) => ({
                      ...prev,
                      onUpdated: !params.onUpdated,
                    }));
                    setRoles((prev) => ({
                      ...prev,
                      selectedPermissions: !roles.selectedPermissions,
                    }));
                    deleteRoleHandler();
                    editRoleHandler();
                    onDeleteRole();
                  }}
                >
                  Delete Role
                </button>
              </div>
            </div>
          </div>
        )}
      </CenterModalAdmin>
      <CenterModalAdmin
        adminModal={true}
        title={"Add New Role"}
        show={modalState?.addRoleModal}
        closeModal={() => addRoleHandler()}
      >
        {/* <AddNewRole setParams={() => setParams()} closeModal={() => addRoleHandler()} data={roles.permissions.data} /> */}

        <div className="xl:h-full overflow-auto">
          <div className="bg-white xl:mb-1">
            <div className="flex md:flex-1 items-center px-4 xl:pb-6 border-b border-solid border-filter-border">
              <div className="xl:py-6 py-3 w-full">
                <div className="font-custom font-semibold text-base text-black-color opacity-80 pb-1">
                  Role Name
                </div>
                <input
                  className="mt-2 border border-solid box-border border-edit-role flex md:flex-1 font-custom font-semibold text-base text-black-color md:py-3 py-2 pl-4 rounded-lg lg:pr-80 pr-0 opacity-80"
                  onChange={(e) => setNames(e.target.value)}
                  value={names === null ? "Enter role name" : names}
                  onFocus={(e) => setNames("")}
                />
              </div>
            </div>
          </div>

          <div className="flex md:flex-1 flex-col items-center px-4 pt-2 xl:pb-6 xl:pt-6 border-b border-solid border-filter-border">
            <div className="font-custom self-start font-semibold text-base text-black-color opacity-80 xl:pb-4">
              Permission
            </div>
            {detailResponse?.permissions?.data?.map((data, index) => {
              const isChecked = selectedPermissionsModal?.indexOf(data) >= 0;
              return (
                <div key={index} className="ml-4 w-full flex items-center">
                  <Box
                    sx={{
                      "& .MuiIconButton-label": { color: "#2E496A" },
                    }}
                  >
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          color="#2E496A"
                          control={
                            <Checkbox
                              checked={isChecked}
                              name={data}
                              onChange={handleOnChangeAddModal}
                              color="default"
                              className="font-custom font-normal text-sm text-black-color"
                            />
                          }
                          label={titleCase(data)}
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </div>
              );
            })}
          </div>
          <div className="flex justify-between xl:pt-6 pt-2">
            <div></div>
            <div className="flex">
              <button
                className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg xl:py-3 xl:px-16 px-12 py-2 bg-border-color text-white mx-auto"
                onClick={onSaveChangesAddModal}
              >
                Add Role
              </button>
            </div>
          </div>
        </div>
      </CenterModalAdmin>
    </div>
  );
}

export default RolesPermission;

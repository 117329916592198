import React, { useState, useEffect } from "react";
import Header from "components/header";
import { Link, useParams } from "react-router-dom";
import backLogo from "assets/arrow back.png";
import ProfileDetail from "./components/ProfileDetail";
import Insight from "./components/Insight";
import Activity from "./components/Activity";
import classNames from "classnames";
import services from "./services";
import homeServices from "pages/Home/services";
import Search from "components/search";
import { Modal, CenterModal } from "components/modal";
import DealershipFilter from "components/modal/DealershipModal";
import Pagination from "components/pagination";
import expandMore from "assets/expand more.png";
import Filter from "components/filterButton";
import usePagination from "utils/usePagination";
import { AdminContext } from "utils/context";
import Cookie from "js-cookie";
// import Error from "assets/error outline.png";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { debounce } from "lodash";

function Profile() {
  const {
    setSortValue,
    setFilterBadge,
    setFilterDealershipBadge,
    setFilterType,
    setFilterDealership,
    setSortSelected,
    setActivitySelected,
    setDealershipSelected,
    setDealershipFilter,
    setOptionsFilter,
    setDateRangeFrom,
    setDateRangeTo,
    setStartDate,
    setEndDate,
    setDateCustom,
    setDateRange,
    setSelected,
  } = React.useContext(AdminContext);

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);

  const [isLoadingSearchResult, setIsLoadingSearchResult] = useState(false);

  // const { thisState, updateInfo } = useContext(AdminContext);

  const [profileTab, setProfileTab] = useState(0);

  const [options] = useState([
    {
      id: 1,
      name: "Profile",
      test: "profile",
    },
    {
      id: 2,
      name: "Insight",
      test: "insight",
    },
    {
      id: 3,
      name: "Activity",
      test: "activity",
    },
  ]);

  const { id } = useParams();

  const [detailResponse, setResponse] = useState({
    profile: {},
  });

  // const [detailTransaction, setResponseTransaction] = useState({
  //   transaction: {}
  // })

  const borderBottom = classNames(
    "border-4 border-solid rounded-sm border-border-color border-t-0 border-l-0 border-r-0"
  );

  const [params, setParams] = useState({
    q: null,
    page: 1,
    limit: 4,
    visible: false,
    onTextSearch: false,
    onSearchResult: false,
    dealership_code: null,
  });

  const [search, setSearch] = useState({
    data: [],
    total: 0,
  });

  const [listFilterDealership, setListFilterDealership] = useState({
    data: [],
    total: 0,
  });

  const [onSearch, setOnSearch] = useState(false);
  const [onAutoCompleteClick, setOnAutoCompleteClick] = useState(true);
  const [modalState, setModalState] = useState({
    dealershipFilterModal: false,
  });
  const [total, setPagination] = usePagination();

  const dealershipFilterHandler = () => {
    setModalState((prev) => ({
      ...prev,
      dealershipFilterModal: !modalState.dealershipFilterModal,
    }));
  };

  const onUserInput = (e) => {
    const textSearch = e.target.value;
    setParams((prev) => ({ ...prev, q: textSearch, page: 1 }));
    // setOnAutoCompleteClick(false);

    if (params.q !== null) {
      setParams((prev) => ({ ...prev, visible: true }));
    }

    if (textSearch === "") {
      setParams((prev) => ({ ...prev, visible: false }));
    }

    clearTimeout(window.timeout);

    window.timeout = setTimeout(() => {
      setParams((prev) => ({ ...prev, onTextSearch: textSearch }));
    }, 1000);
  };

  function onEnter(e) {
    if (e.key === "Enter") {
      setParams((prev) => ({
        ...prev,
        visible: false,
        onSearchResult: true,
        page: 1,
      }));
      setOnAutoCompleteClick(false);
    } else if (e.key === "Escape") {
      setParams((prev) => ({
        ...prev,
        visible: false,
        onSearchResult: false,
        page: 1,
      }));
      setOnAutoCompleteClick(true);
    }
  }

  useEffect(() => {
    //   services.getTransactionType((response, error) => {
    //     if (error) {
    //       console.log(error);
    //       if (error.message === "Request failed with status code 401" ) {
    //         Cookie.remove('SID_OR');
    //         Cookie.remove('SID_OR_PERMISSIONS');
    //         Cookie.remove('SID_OR_USER_TYPE');
    //         window.location.replace('/login');
    //       }
    //     }
    //     setResponseTransaction(prev => ({ ...prev, transaction: response }))
    //  });

    homeServices.getDealership({
      success: (response) => {
        setListFilterDealership(response);
      },
      error: (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      },
    });
  }, [params]);

  useEffect(() => {
    if (params.q) {
      setIsLoadingSearch(true);
      setIsLoadingSearchResult(true);
      homeServices.getUserSearch(
        {
          q: params.q,
          per_page: params.limit,
          dealership_code: params.dealership_code,
          page: params.page,
        },
        {
          success: (response) => {
            setIsLoadingSearch(false);
            setIsLoadingSearchResult(false);
            setSearch({ data: response });
            setPagination(response.per_page, response.last_page);
          },
          error: (err) => {
            if (err.message === "Request failed with status code 401") {
              Cookie.remove("SID_OR");
              Cookie.remove("SID_OR_PERMISSIONS");
              Cookie.remove("SID_OR_USER_TYPE");
              window.location.replace("/login");
            }
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    setIsLoadingProfile(true);
    services.getProfileById({ id }, (response, err) => {
      if (!err) {
        setIsLoadingProfile(false);
        setResponse({ profile: response });
        // console.log(response)
      } else if (err) {
        if (err.message === "Request failed with status code 401") {
          Cookie.remove("SID_OR");
          Cookie.remove("SID_OR_PERMISSIONS");
          Cookie.remove("SID_OR_USER_TYPE");
          window.location.replace("/login");
        }
      }
    });
    setSortValue(null);
    setFilterBadge(0);
    setFilterDealershipBadge(0);
    setFilterType([]);
    setFilterDealership([]);
    setSortSelected(0);
    setActivitySelected([]);
    setDealershipSelected([]);
    setOptionsFilter([]);
    setDealershipFilter([]);
    setDateRangeFrom(null);
    setDateRangeTo(null);
    setStartDate(new Date());
    setEndDate(new Date());
    setDateCustom(null);
    setDateRange(null);
    setSelected("12months");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  let permisi = Cookie.get("SID_OR_PERMISSIONS");
  const memoizedProfile = React.useMemo(
    () => detailResponse?.profile,
    [detailResponse?.profile]
  );
  // console.log(memoizedProfile.data)
  return (
    <>
      <div className="bg-profile-bg font-custom h-screen">
        <div
          className={
            params.onSearchResult
              ? "lg:h-screen lg:overflow-auto"
              : "lg:overflow-auto"
          }
        >
          <div
            className="lg:block"
            style={{
              background: "linear-gradient(180deg, #2E496A 0%, #526C8B 100%)",
            }}
          >
            <Header />
            <div className="hidden lg:flex flex-1 mt-6 pb-4 overflow-scroll lg:overflow-auto">
              <Search
                placeholder="Search Customer Here"
                name="search"
                classContainer="w-11/12 mx-auto"
                className="outline-none"
                onChange={(e) => onUserInput(e)}
                value={params.q}
                visible={params.visible}
                onSearch={params.onTextSearch}
                onClose={() => params({ visible: false })}
                listKeyword={search?.data?.data}
                onAutoCompleteClick={() => {
                  setParams((prev) => ({
                    ...prev,
                    onSearchResult: false,
                    visible: false,
                    page: 1,
                  }));
                  setOnAutoCompleteClick(true);
                  setOnSearch(false);
                }}
                onKeyDown={(e) => onEnter(e)}
                isLoading={isLoadingSearch}
              />
            </div>
            {params.onSearchResult === true && onSearch === false && (
              <div
                className="w-6/12 pb-4 md:w-3/12 lg:w-56 lg:pl-12 xl:pl-16 xl:w-64"
                style={{ width: "fit-content" }}
              >
                <Filter
                  style={{ width: "fit-content" }}
                  className="py-1 px-3 text-border-color border border-solid border-border-color rounded-3xl bg-white"
                  placeholderName="Filter by Dealership:"
                  items={listFilterDealership?.data}
                  options={
                    params.dealership_code === null
                      ? "All Dealership"
                      : params.dealership_name
                  }
                  imageIcon={expandMore}
                  searchButton={true}
                  homeFilter={true}
                  onClick={(dealer) => dealershipFilterHandler(dealer)}
                  onImageOpen={modalState?.dealershipFilterModal}
                />
              </div>
            )}
            <Modal
              onDealershipModal={true}
              title={"Filter by Dealership"}
              show={modalState?.dealershipFilterModal}
              closeModal={() => dealershipFilterHandler()}
            >
              <DealershipFilter
                data={listFilterDealership?.data}
                setDealershipCode={(data, name) =>
                  setParams((prev) => ({
                    ...prev,
                    dealership_code: data,
                    dealership_name: name,
                  }))
                }
                closeModal={() => dealershipFilterHandler()}
              />
            </Modal>
            <CenterModal
              title={"Filter by Dealership"}
              show={modalState?.dealershipFilterModal}
              closeModal={() => dealershipFilterHandler()}
            >
              <DealershipFilter
                data={listFilterDealership?.data}
                setDealershipCode={(data, name) =>
                  setParams((prev) => ({
                    ...prev,
                    dealership_code: data,
                    dealership_name: name,
                  }))
                }
                closeModal={() => dealershipFilterHandler()}
              />
            </CenterModal>
          </div>
          {isLoadingSearchResult && params.onSearchResult === true && (
            <div className="flex w-full py-5 justify-center">
              <CircularProgress color="black" size={20} />
            </div>
          )}
          <div
            className={
              params.onSearchResult === true
                ? "my-auto pt-8 pb-6 lg:grid grid-cols-3 grid-flow-row gap-4 w-10/12 mx-auto px-auto hidden"
                : "lg:flex hidden"
            }
          >
            {params.onSearchResult === true &&
              search?.data?.data?.map((result, index) => {
                const url = `/profile/${result.orag_id}`;
                return (
                  <div
                    className="bg-white pl-6 pt-4 pb-4"
                    style={{ border: "1px solid #EBEEF2" }}
                    key={index}
                    onClick={() => {
                      setParams((prev) => ({ ...prev, onSearchResult: false }));
                      setOnAutoCompleteClick(true);
                    }}
                  >
                    <Link to={url}>
                      <div className="w-11/12">
                        <div className="text-sm	font-semibold">
                          {result.fullname}
                        </div>
                        <div className="text-sm font-normal text-neutral-color truncate">
                          {result.email}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
          {params.onSearchResult === true && (
            <div className="w-full hidden lg:inline-flex flex-1 justify-center items-center pb-28">
              <div className="w-3=2/12 mx-auto">
                <Pagination
                  count={total}
                  page={params.page}
                  onChange={(event, page) =>
                    setParams((prev) => ({ ...prev, page: page }))
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className={!onAutoCompleteClick ? "lg:hidden" : "lg:inline"}>
          <div className="lg:w-full lg:bg-white">
            <div className="lg:py-8 lg:mx-auto lg:justify-between lg:flex lg:w-11/12 lg:bg-white lg:border-b lg:border-solid lg:border-center-modal-border">
              <div className="bg-white pl-4 lg:pl-2 py-4 border-b border-solid border-center-modal-border lg:border-0">
                {/* <Link to={'/profile'}> */}
                <div className="flex lg:ml-0">
                  <Link to={"/"}>
                    <img src={backLogo} alt="" className="w-auto h-8 mr-4" />
                  </Link>
                  <div>
                    <div className="text-sm	font-semibold">
                      {memoizedProfile?.data?.fullname}
                    </div>
                    <div className="text-sm font-normal text-neutral-color">
                      {memoizedProfile?.data?.email}
                    </div>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <div className="lg:justify-between lg:w-8/12 xl:w-1/2 bg-white flex flex-col lg:flex-row lg:border-0 pt-4 pb-5 border-b border-solid border-center-modal-border">
                <div className="lg:hidden lg:ml-10 ml-6 font-semibold text-sm">
                  RFV Score
                  <div className="font-normal">
                    {memoizedProfile?.data?.rfv_score}
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="hidden lg:block lg:ml-0 ml-6 font-semibold text-sm">
                    RFV Score
                    <div className="font-normal">
                      {memoizedProfile?.data?.rfv_score}
                    </div>
                  </div>
                  {/* <div className="lg:ml-10 ml-6 font-semibold text-sm">
                    Segment
                    <div className="font-normal">
                      Loyal
                    </div>
                  </div> */}
                  <div className="lg:hidden flex flex-row ml-6">
                    <div className="lg:hidden font-semibold text-sm mr-5 lg:mr-0">
                      Club OpenRoad
                      <div className="flex flex-row items-center font-normal">
                        <div className="bg-green-badge rounded px-2 py-1 text-white mr-1 text-xxs leading-3">
                          Active
                        </div>
                        {memoizedProfile?.data?.club_info?.available_points}{" "}
                        points
                      </div>
                    </div>
                    <div className="flex lg:hidden flex-col font-semibold text-sm mr-5 lg:mr-0">
                      <div className="flex flex-row">
                        Customer Type
                        {/* <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4.5C0.25 2.43 1.92625 0.75 3.99625 0.75C6.07 0.75 7.75 2.43 7.75 4.5C7.75 6.57 6.07 8.25 3.99625 8.25C1.92625 8.25 0.25 6.57 0.25 4.5ZM4.375 3C4.375 2.79375 4.20625 2.625 4 2.625C3.79375 2.625 3.625 2.79375 3.625 3V4.5C3.625 4.70625 3.79375 4.875 4 4.875C4.20625 4.875 4.375 4.70625 4.375 4.5V3ZM4 7.5C2.3425 7.5 1 6.1575 1 4.5C1 2.8425 2.3425 1.5 4 1.5C5.6575 1.5 7 2.8425 7 4.5C7 6.1575 5.6575 7.5 4 7.5ZM3.625 5.625V6.375H4.375V5.625H3.625Z" fill="black"/>
                        </svg> */}
                      </div>
                      <div className="font-normal">
                        {memoizedProfile?.data?.is_b2b === 0
                          ? `Individual`
                          : `Company`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden lg:inline font-semibold text-sm mr-10 lg:mr-0">
                  Club OpenRoad
                  <div className="flex flex-row items-center font-normal">
                    <div className="bg-green-badge rounded px-2 py-1 text-white mr-1 text-xxs leading-3">
                      Active
                    </div>
                    {memoizedProfile?.data?.club_info?.available_points} points
                  </div>
                </div>
                <div className="hidden lg:flex lg:flex-col font-semibold text-sm mr-10 lg:mr-0">
                  <div className="flex flex-row">
                    Customer Type
                    {/* <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 4.5C0.25 2.43 1.92625 0.75 3.99625 0.75C6.07 0.75 7.75 2.43 7.75 4.5C7.75 6.57 6.07 8.25 3.99625 8.25C1.92625 8.25 0.25 6.57 0.25 4.5ZM4.375 3C4.375 2.79375 4.20625 2.625 4 2.625C3.79375 2.625 3.625 2.79375 3.625 3V4.5C3.625 4.70625 3.79375 4.875 4 4.875C4.20625 4.875 4.375 4.70625 4.375 4.5V3ZM4 7.5C2.3425 7.5 1 6.1575 1 4.5C1 2.8425 2.3425 1.5 4 1.5C5.6575 1.5 7 2.8425 7 4.5C7 6.1575 5.6575 7.5 4 7.5ZM3.625 5.625V6.375H4.375V5.625H3.625Z" fill="black"/>
                    </svg> */}
                  </div>
                  <div className="font-normal">
                    {memoizedProfile?.data?.is_b2b === 0
                      ? `Individual`
                      : `Company`}
                  </div>
                </div>
                <div className="ml-6 lg:ml-0 lg:mt-0 mt-4 text-sm">
                  Transaction Type
                  <div className="text-xs pt-1">
                    {memoizedProfile?.data?.transaction_types?.map(
                      (data, index) => (
                        <span
                          className="border-border-color border border-solid rounded px-2 py-1 text-border-color mr-1"
                          key={index}
                        >
                          {data}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="flex pt-4 flex-1 lg:flex-none justify-around items-center lg:w-1/2 lg:ml-10">
              {options.map((option, index) => {
                return (
                  <div key={index}>
                    <button
                      disabled={
                        permisi.indexOf(`view customer ${option.test}`) <= 0
                      }
                      className={
                        profileTab === index
                          ? classNames(
                              `w-full lg:pb-2 ${
                                permisi.indexOf(
                                  `view customer ${option.test}`
                                ) < 0
                                  ? "hidden"
                                  : ""
                              }`,
                              borderBottom
                            )
                          : classNames(
                              `w-full lg:pb-2 ${
                                permisi.indexOf(
                                  `view customer ${option.test}`
                                ) < 0
                                  ? "hidden"
                                  : ""
                              }`
                            )
                      }
                      onClick={() => {
                        setProfileTab(index);
                      }}
                    >
                      {option.name}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          {isLoadingProfile && (
            <div className="flex w-full py-10 justify-center">
              <CircularProgress color="inherit" size={20} />
            </div>
          )}
          {profileTab === 0 && (
            <div className="bg-profile-bg">
              <ProfileDetail detailResponse={memoizedProfile} />
            </div>
          )}
          {profileTab === 1 && (
            <div className="bg-profile-bg">
              <Insight id={id} />
            </div>
          )}
          {profileTab === 2 && (
            <div className="bg-profile-bg">
              <Activity detailResponse={memoizedProfile} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Profile;
